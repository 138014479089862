/*
 * @Author: dongjia
 * @Date: 2021-06-25 17:25:28
 * @LastEditTime: 2021-08-04 18:34:54
 * @LastEditors: aleaner
 * @Description: 分类管理列表接口
 * @FilePath: \saas-admin-vue\src\modules\wx-company\api\wx-company-plugin.js
 * 怎么可能会有bug！！！
 */
import api from '@/base/utils/request';

// 获取插件配置状态
export const PluginStatus = data => {
  return api({
    url: '/admin/wx_open/wx_company/getPluginStatus',
    method: 'post',
    data,
    notCancel: true
  });
};

// 修改插件状态
export const changePlugin = data => {
  return api({
    url: '/admin/wx_open/wx_company/changePlugin',
    method: 'post',
    data,
    notCancel: true
  });
};

export const getIndexData = () => {
  return api({
    url: '/admin/app_design/project/index',
    method: 'post'
  })
}