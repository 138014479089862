<!--
 * @Author: dongjia
 * @Date: 2021-06-25 16:57:10
 * @LastEditTime: 2021-08-23 18:36:39
 * @LastEditors: aleaner
 * @Description: 企微插件应用页面
 * @FilePath: \saas-admin-vue\src\modules\wx-company\views\wx-company-plugin.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="list-page" v-loading="loading">
    <el-tabs v-if="pluginData && keyArr.length > 1" style="margin-bottom: 22px" v-model="activeName"
      @tab-click="isOpen = pluginData[activeName]">
      <el-tab-pane :label="tab.name" :name="tab.id" v-for="tab in keyArr" :key="tab.id">
      </el-tab-pane>
    </el-tabs>
    <div class="top-btn" v-if="!isOpen">
      <el-button type="primary" size="small" :loading="updateLoading" @click="handleChangeStatus(1)">开通插件
      </el-button>
    </div>
    <div class="top-btn" v-else>
      <el-button type="danger" size="small" :loading="updateLoading" @click="handleClose">关闭插件
      </el-button>
    </div>
    <div class="live-tips">
      <div class="tips-title">
        <img style="width: 28px;height:28px;margin-right: 5px"
          src="@/modules/system-admin/assets/images/icon-title.png"> 温馨提示
      </div>
      <div class="tips-content">
        <div class="item-tip"><span class="tip-header">·</span><span>开通插件前，请先前往 <a
              href="https://mp.weixin.qq.com/" target="_blank">微信公众平台</a>
            ，登录小程序账号，在小程序管理后台申请使用插件，小程序管理后台添加路径：设置
            ->第三方服务 -> 插件管理 -> 添加插件。</span></div>
        <div class="item-tip"><span style="margin-right: 10px">·</span>
          <span>搜索并添加以下插件ID，企业客户联系插件ID：wx104a1a20c3f81ec2、企业客户服务插件ID：wxaae6519cee98d824。<a
              href="https://www.yuque.com/docs/share/66928c79-9c01-438e-9f6b-d346df696849?# 《企业微信插件应用开通指引》"
              target="_blank">查看添加插件指引 </a></span>
        </div>
        <div v-if="paramsData" class="item-tip"><span style="margin-right: 10px">·</span>
          <span>开通插件后，请前往 <router-link
              :to="{ name: 'AppDesignPage', params: {platform: paramsData.platform,id: paramsData.id}}"
              target="_blank">页面装修
            </router-link> -> 小程序装修页面 -> 系统页：企微联系配置数据，以便达到预览图的效果。</span>
        </div>
      </div>
    </div>
    <div>
      <div class="modular" style="margin-top: 30px">
        <p class="modular-title">企业客户联系插件 效果预览</p>
        <div class="modular-content">
          <el-image class="modular-image" v-for="(img, index) in contactMePreviewImgs" :src="img"
            @click="$previewImage(contactMePreviewImgs,index)" fit="cover" :key="index"></el-image>
        </div>
      </div>
      <div class="modular" style="margin-top: 30px">
        <p class="modular-title">企业客户服务插件 效果预览</p>
        <div class="modular-content">
          <el-image class="modular-image" v-for="(img, index) in groupChatPreviewImgs" :src="img"
            @click="$previewImage(groupChatPreviewImgs,index)" fit="cover" :key="index"></el-image>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  PluginStatus,
  changePlugin,
  getIndexData,
} from "../api/wx-company-plugin";
export default {
  data() {
    return {
      loading: false, //加载
      updateLoading: false, // 拉取数据加载
      pluginData: null,
      keyArr: [
        { name: "企业客户联系、服务插件", id: "contact_status" },
        // { name: "企业客户服务插件", id: "group_status" },
      ], // 插件关键字
      contactMePreviewImgs: [
        require("../static/images/contact-me-preview1.png"),
        require("../static/images/contact-me-preview2.jpg"),
        require("../static/images/contact-me-preview3.jpg"),
      ],
      groupChatPreviewImgs: [
        require("../static/images/group-chat-preview1.png"),
        require("../static/images/group-chat-preview2.jpg"),
        require("../static/images/group-chat-preview3.jpg"),
      ],
      activeName: "contact_status",
      isOpen: 0, // 是否开启小程序直播
      paramsData: null,
    };
  },
  watch: {},
  methods: {
    getPluginStatus() {
      this.loading = true;
      PluginStatus()
        .then((res) => {
          this.pluginData = res.data;
          this.isOpen = res.data[this.activeName];
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 关闭插件
    handleClose() {
      this.$msgbox
        .confirm("确定关闭 “企业客户联系、服务插件” 插件应用吗？", "提示", {
          type: "warning",
        })
        .then((Response) => {
          this.handleChangeStatus(0);
        })
        .catch((err) => {});
    },
    handleChangeStatus(isOpen) {
      this.updateLoading = true;
      const data = {
        is_open: isOpen,
      };
      changePlugin(data)
        .then((res) => {
          if (res.data.status === 1) {
            this.$message.success(isOpen ? "开通成功" : "关闭成功");
            this.getPluginStatus();
            this.updateLoading = false;
          } else {
            this.$msgbox
              .confirm(res.msg, "提示", {
                type: "warning",
                confirmButtonText: "前往",
              })
              .then((Response) => {
                this.$router.push({ name: "WxCompanyConfig" });
              })
              .catch((err) => {});
          }
        })
        .catch((err) => {
          this.updateLoading = false;
        });
    },
  },
  created() {
    this.loading = true;
    if (this.$route.query.activeName) {
      this.activeName = this.$route.query.activeName;
    }
    getIndexData()
      .then((res) => {
        this.paramsData = res.data.find((el) => el.platform === "mp-weixin");
      })
      .catch((err) => {});
    this.getPluginStatus(); //获取插件状态
  },
};
</script>

<style lang="scss" scoped>
.live-tips {
  background: #ebf1ff;
  border: 1px solid #d7e4ff;
  border-radius: 2px;
  margin-bottom: 20px;
  padding: 20px;
  a {
    text-decoration: none;
    color: #3576ff;
  }
  .tips-title {
    color: #3576ff;
    font-size: 18px;
    font-weight: 400;
    display: flex;
    align-items: center;
  }
  .tips-content {
    margin-left: 32px;
    .item-tip {
      font-size: 14px;
      line-height: 24px;
      display: flex;
      .tip-header {
        margin-right: 10px;
      }
    }
  }
}

.modular-content {
  padding: 40px;
  background-color: rgba(242, 242, 242, 1);
  .modular-image {
    width: 230px;
    height: 409px;
    margin-right: 60px;
  }
}
</style>
